@import './globalColorVars.scss';
@import './font-family.scss';
@import './global.scss';
@import './mixins.scss';
@import './buttons.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// barfstrap media queries
//@include media-breakpoint-up(sm)
//@include media-breakpoint-up(md)
//@include media-breakpoint-up(lg)
//@include media-breakpoint-up(xl)
//@include media-breakpoint-up(xxl)

.svg-inline--fa {
 height: 1em;
}

.column {

 .card {
   box-shadow: 0 0 3px $box-shadow-color;
   box-sizing: border-box;
   border: 1px solid $bt-white;
   border-radius: 10px;
   padding: 0;
   display: flex;
   position: relative;
 }
}

.pop-talks-item {
  font-size: 16px;

  &:hover {
   box-shadow: 0 0 15px $box-shadow-color;
  }
}


.img-wrap {
 //position: relative;
 //padding-bottom: 50%;

 a {
  display: block;
 }

 img {
  // the react Image elements wrapping div, border radius can only work here
  border-radius: 10px 10px 0 0;
 }

}

.item-image {
  display: block;
  height: auto;
  max-width: 100%;
}

.cardbody {
  padding: 15px;
  border-radius: 0 0 10px 10px;
  display: flex;

  .body-comp-logo {
   flex: 1 0 auto;
   position: relative;
   margin-right: 15px;
   width: 30px;
   height: 30px;
   font-size: 12px;
  }

  .main-body {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   width: calc(100% - 30px);

   // set the footer to bottom
   .item-meta {
    display: flex;
    flex-direction: row;
    align-self: baseline;
    margin-top: auto;
    width: 100%;
   }
 }
}

.item-title {
  font-size: 16px;
  color: $primary-text;
  @include roboto-medium;
  margin-bottom: 10px;
}

// search page item titles
.talks-grid-search,
.search-summit-item,
.search-series-item,
.search-channels-item {

 .item-title {
  font-size: 18px;
 }
}

.search-series-item{
  .presenter{
    font-size: 12px;
    @include roboto-medium;
    margin-top: auto;
  }
}



.card-description {
  display: inline-block;
  font-size: 14px;
  @include roboto-light;
  color: $secondary-text;
  margin-bottom: 20px;
}

.presenter {
 font-size: 14px;
 @include roboto-light;
 color: $secondary-text;
}

.item-meta {
  color: $primary-text;
  font-size: 14px;
  @include roboto-light;
  width: 100%;
}

.series-search-logo.body-comp-logo {
 background: 0;
 min-height: 30px;
 width: auto;

 span {
  float: left;
  margin: 3px 15px 0 0;
 }

 div {
  display: inline-block !important;
  float: left;
  height: 30px;
  width: 30px;
 }
}

/* === Style the grid items as vertical lists === */

//vertical-list layout single column top level bt row element
// Use this block to style generic elements in a vertical list

// As prenters are only shown on the search grid we have an issue
// trying to show two diff layouts. This small block
// will hide the comp logo to start with for mobile and show on medium up horizontal design

.vertical-list {
 .presenter {
   display: none;
 }
}

.vertical-list {

 .column {
  padding-left: 0 !important;
 }

 .ChannelsCard {
  padding: 15px 10px;
 }

 .search-channels-item {

  flex-direction: row-reverse;

  .col-1-item-body {
   width: 80%;
   flex: 1 0 80%;

   .cardbody {
    padding: 0;
   }
  }

  .col-2-img {
   display: block;
   width: 20%;
   flex: 0 0 20%;
   position: relative;
   padding-left: 0;

   .img-wrap {
    display: block;
    padding: 0 15px;
    margin-right: 15px;
    width: 100%;

    .body-comp-logo {
     width: 50px;
     height: 50px;
     position: relative;
    }

    img {
     //background-color: $background-alt;
    }

    div {
     border-radius: 0;
    }

    img {
     border-radius: 0;
     opacity: 1;
    }
   }
  }
 }
}

@include media-breakpoint-up(sm) {

 .vertical-list {
  margin: 0;

  @include media-breakpoint-up(lg) {
   width: 70%;
  }

  > .column {
   margin-top: 0;

   .search-channels-item {
    align-items: center;
   }

   .card {
    box-shadow: none;
   }
  }

  .horizontal-card {
   border-radius: 0;
   border: 0;
   border-bottom: 1px solid $border-divider-color;
   flex-direction: row;
   padding: 20px 0;

   .img-wrap,
   .body-comp-logo {
    margin-right: 15px;
    //width: 40% !important;
    flex: 1 0 344px;

    img {
     border-radius: 10px;
     vertical-align: top;
    }
   }
   // for series / summits search allow image flex block to shrink
   .img-wrap {
    flex: 1 1 344px;
   }

   .cardbody {
    //width: 60% !important;
    flex: 1 1 60%;
    padding: 0;

    .presenter {
     display: flex;
     flex-direction: row;

     .body-comp-logo {
      display: block;
      flex: 0 0 30px;
      margin-right: 10px;
      height: 30px;
      width: 30px;

      img {
       border-radius: 0;
      }
     }

     //Adjust the  body-comp-logo container flex width for series search
     .series-search-logo {
      flex: 0 0 70px;
     }

     .presenter-span{
      flex-basis: auto;
      align-self: center;
     }
    }
   }
  }

  .search-channels-item {

   flex: 1 0 100%;
   box-sizing: border-box;

   .col-1-item-body {
    flex: 1 0 60%;
    width: 60%;

    .cardbody {
     width: 100%;
    }
   }

   .col-2-img {
    flex: 0 0 150px;
    width: 150px;
    margin-right: 15px;

    .img-wrap {

     .body-comp-logo {
      width: 150px;
      height: 150px;
      //position: static;
     }

     div {
      border-radius: 0;
     }

     img {
      border-radius: 0;
      opacity: 1;
     }
    }
   }

  }

  .search-series-item {

    .body-comp-logo {

     flex: 1 0 auto;

     div {
      width: 70px;

      img {
       border-radius: 0;
      }
     }

    }
  }

 }
}

// Classed on the div ( BT Card component element )
// the class lives nested in the vertical list element.
.talks-grid-search {

 height: 100%;

 .img-wrap {

 }

 .cardbody {

 }
}

// Block manages the on hover/focus image overlay icons for talks cards

.card:not(.no-img-hover) {

 transition: box-shadow .3s ease-in-out;

 .img-wrap {
  background: no-repeat center/73px 83px !important;
  //height: 100%;
  width: 100%;
  border-radius: 10px 10px 0 0;
  color: #fff;
  transition: background .3s ease-in-out;
 }

 img {
  opacity: 1;
  transition: opacity .3s ease-in-out !important;
 }

 &.recorded,
 &.live,
 &.processing,
 &.upcoming {
  .link-img-hover:hover,
  .link-img-hover:focus {

    ~ .img-wrap {
     background-color: #000 !important;

    img {
     opacity: 0.3;
     transition: opacity .3s ease-in-out !important;
    }
   }
  }
 }

 &.upcoming {

  .img-wrap {
    background-image: url('/portal/icons/calendar-day-4x.png') !important;
  }
 }

 &.recorded,
 &.live,
 &.processing {

  .img-wrap {
   background-image: url('/portal/icons/play-4x.png') !important;
  }
 }

 .channel-item {
  .img-wrap {

   div  {
    border-radius: 10px;
   }
  }
 }
}


// For vertical lists cards have no hover overlay
// Card background has a hover `& requires pading to prevent
// inner elements touching edge of background.
// Single column stacked card should display as per grid cards but without hover ovelay
.vertical-list {

 .horizontal-card {
  transition: background .3s ease-in-out !important;


  &:hover,
  &:focus {
   background: $background-alt;
  }
  .link-img-hover:hover,
  .link-img-hover:focus {
   ~ .img-wrap {

    img {
     opacity: 1;
    }

    div {
     background: 0;
    }
   }
  }
 }
}

// At card horizontal layout we need padding on the Car element
// & to ensure the botton img elemnent has border radius

@include media-breakpoint-up(md) {
 .vertical-list {

  .horizontal-card {
   padding: 15px 10px;

   .link-img-hover:hover,
   .link-img-hover:focus {
    ~ .img-wrap {

     div {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
     }
    }
   }
  }
 }
}
// Live label - latest talks cards, channel featured webcast images.
.card {

 // force barfstrap streached-link to have a higher z-index
 // to overlay the live label and date so hovering either is NOT a dead spot
 .link-img-hover {

  z-index: 10 !important;
 }

 &.live {

  .img-wrap {
   position: relative;

   .live-label {
    background: $background-live-now;
    border: 1px solid $bt-white;
    border-radius: 7px;
    color: $bt-white;
    @include roboto-regular;
    font-size: 12px;
    position: absolute;
    top: 3px;
    left: 3px;
    padding: 2px 6px 1px 6px;
    text-align: center;
    width: auto;
    height: auto;
    z-index: 2;
   }
  }
 }
}

// Block manages the on hover/focus image overlay icons for channel featured webcasts
// Overlay is not required on


.ChannelsCard:not(.no-img-hover) {

 .img-wrap {

  a {
   background: no-repeat  center/73px 83px !important;
   border-radius: 10px;
   display: block;
   transition: background .3s ease-in-out !important;
  }

  img {
  opacity: 1;
  transition: opacity .3s ease-in-out !important;
  }
 }



 .link-img-hover:hover,
 .link-img-hover:focus {

  a {
   background-color: #000 !important;
  }

  img {
   opacity: 0.4;
  transition: opacity .3s ease-in-out !important;
  }
 }


 &.recorded,
 &.live,
 &.processing {

  .img-wrap {

   a {
    background-image: url('/portal/icons/play-4x.png') !important;
   }
  }

  // Live label needs this as it's set as a child of img anchor wrap
  a {
   display: block;
   position: relative;
  }
 }

  // set specific icons per status

 &.upcoming {

  .img-wrap {

   a {
    background-image: url('/portal/icons/calendar-day-4x.png')  !important;
   }
  }
 }
}

// Card image properties - added here as locally in image component is too late

.item-image-summits {
 width: 1200px;
}

.item-image-webcasts {
 width: 640px;
}

