@import '@bt-branding/colors';
@import './globalColorVars.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
.col {
  .card {
    box-shadow: 0 0 3px $box-shadow-color;
    box-sizing: border-box;
    border: 1px solid $bt-default;
    border-radius: 10px;
    padding: 0;
    display: flex;
    position: relative;
  }
}

.skeleton-loading {
	animation: skeleton 1s ease infinite alternate;
}

@keyframes skeleton {
	to {
		opacity: 0.5;
	}
}

.img-wrap {
  position: relative;
  padding-bottom: 50%;
  background-color: $bt-tertiary;
}

.cardbody {
  padding: 15px;
  border-radius: 0 0 10px 10px;
  display: flex;

  .body-comp-logo {
    position: relative;
    margin-right: 15px;
    width: 30px;
    height: 30px;
  }

  .main-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 30px);
	height: 155px;

    .card-description {
      width: 100%;
      height: 56px;
      background-color: $bt-tertiary;
    }

    .item-title {
      width: 100%;
      height: 41px;
      background-color: $bt-tertiary;
    }
  }
}

.body-comp-logo {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: $bt-tertiary;
}

.vertical-list{
  .horizontal-card{
    &:hover{
      background: none !important;
    }
  }

  .body-comp-logo{
    align-self: center;
  }

  .img-wrap{
    border-radius: 10px;
    vertical-align: top;
    padding: 0;
  }

  .main-body{
    .presenter-span,
    .item-meta{
      width: 100%;
      background-color: $bt-tertiary;
    }
  }

}

.section-headings {

  margin-top: 60px;

  // represents the first block on the page
  // passed in as props from the  skeleton component call
  // currently talk page needs no margin top & topic pages need 30px.
  &.no-margin-top {
   margin-top: 0;
  }

  &.first {
   margin-top: 30px;
  }

 .section-title,
 .section-heading-description {
   background-color: $bt-tertiary;
   margin-bottom: 20px;
 }

  .section-title {
   display: block;
   //margin-top: 60px;
   height: 30px;
   width: 150px;
  }

  .section-title.first {
    margin-top: 0;
  }

  .section-heading-description {
    height: 30px;
    width: 40%;
  }
}

@include media-breakpoint-down(sm) {

 .section-headings {

  .section-heading-description {
    height: 100px;
    width: 60%;
  }
 }

  .vertical-list{
    .talks-grid-search{
      height: auto;
    }
    .horizontal-card{
      margin-bottom: 15px;
      .img-wrap{
        padding-bottom: 50% ;
        border-radius: 0;
      }
      .search-channels-item{
        .col-2-img{
          .img-wrap{
            width: 50px;
            height: 50px;
            position: relative;
            margin-left: 8px;
          }
        }
      }
    }
  }

}
