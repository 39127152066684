
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars';

.mybrighttalk-message-link {

  a {
    svg {
      color: $link-primary;
    }
  }
}

.message-title {
  margin-top: 30px;
}
