@use "sass:math";

$baseSize: 16px;

@function calculateRem($value) {

  $remSize: math.div($value, $baseSize);
  @return #{$remSize}rem;

}

@mixin fontSize($size) {

  font-size: $size; //Fallback in px
  font-size: calculateRem($size);

}
