
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../../style/skeletonGrids.scss';
@use '../../../style/grids.scss';

.skeleton-summits-wrap {
	padding: 15px 15px 15px 0;
}

.img-wrap {
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.item-title {
	margin-bottom: 15px;
}

.vertical-list{
  .img-wrap{
    border-radius: 0;
  }
  .main-body{
    width: 100%;
    .item-title,
    .item-meta,
    .card-description{
      height: 20px;
      margin-top: 5px;
    }
    .card-description{
      height: 20px;
    }
  }
}
