
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../../style/grids.scss';
@import '../../../style/globalColorVars.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.ChannelsCard {

  .cardbody {
   padding: 15px 0 15px 15px;
   height: 100%;
   width: 100%;

   .item-meta {
    margin-bottom: 0;

    // unsubscribe button is unique to user channels
    .unsubscribe-channel-link, .view-channel-link {
      cursor: pointer;
      @include roboto-regular;
      color: $primary-text;
      padding-left: 30px;
    }

	.channel-action-link,
  .text-link-primary {
		font-size: 14px;
		svg {
			color: $link-primary;
		}
	}
   }

   .body-comp-logo {
    width: 50px;
    height: 50px;
    margin-right: 15px;
   }
  }

  .body-header {
    margin-bottom: 15px;
  }

  .img-wrap {
    padding: 15px 15px 15px 0;
    height: 100%;

    img,
    .no-talks-wrap {
      border-radius: 10px;
    }
  }

  .subs-talks-counts {
   font-size: 14px;
   color: $primary-text;
   @include roboto-light;
  }

  .no-talks-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-alt;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
  .no-talks-scheduled-text {
    color: $primary-text;
    margin: 0;
  }
}

// homepage or general channel grid
.channel-item {
 .cardbody {
  .body-comp-logo {
   flex: 1 0 auto;
  }
  .item-meta{
    margin-top: auto;
  }
 }
}

@include media-breakpoint-down(xl) {

  .col-1-item-body {
    width: 100%;
  }

  .col-2-img {
    display: none;
  }

	.img-wrap {
		display: none;
	}
	.ChannelsCard {
	.cardbody {
		padding: 15px;
	}
}
}

.vertical-list {
 .horizontal-card {
  .cardbody {

  }
 }
}
// channel page requires different views for images.

.channel-page-channels {

  // Flex basis is being set to shrink , with margin right means we get less that 50px width
  // try and override whatever set this basis
  .body-comp-logo {
    flex: 0 0 50px;
  }
  .main-body{
    .item-meta{
      margin-top: auto;
    }
  }
}


@include media-breakpoint-up(sm) {

 .channel-page-channels {

  .col-2-img,
  .img-wrap {
    display: block;
  }
  .col-1-item-body {
    width: 50%;
  }
 }
}


.unsubscribe-card {

 // min height needed as flexbox sets height according to content if we
 // open two unsubscribes on same row then the box height will shrink as it has less content
 // than a regular card.
 min-height: 201px;

  p.alert-icon-wrap {
   margin-top: 20px;
  }

  .channels-loading {
	width: 36px;
	height: 36px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
  }


  .alert-icon {
   color: $icon-red;
   font-size: 26px;
   margin-bottom: 10px;
  }

  .cardbody {
    color: $primary-text;
    display: flex;
    flex-direction: column;
    align-items: center;

    .unsubscribe-title {
     font-size: 16px;
     @include roboto-medium;
    }

    .unsubscribe-instruction {
     font-size: 14px;
     @include roboto-light;
    }
    .unsubscribe-links {
     display: flex;
     flex-direction: row;
     align-self: baseline;
     margin-top: auto;
     width: 100%;
     align-items: center;
     justify-content: center;

     .link-wrap {
      @include roboto-regular;
      font-size: 14px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: 50%;
      justify-content: space-between;
      padding-bottom: 10px;

      @include media-breakpoint-up(lg) {
       width: 30%;
      }

      .delete-channel {
       color: $icon-red;
       margin-right: 20px;
      }
     }
    }

  }
}
