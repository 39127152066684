@import './globalColorVars.scss';

svg.fa-calendar-day,
.bt-icons {
 color: $bt-light-grey;
}

@mixin roboto-light {
 font-family: 'Roboto Light', 'Roboto', Arial, sans-serif;
 font-weight: 300;
 font-style: normal;
}

@mixin roboto-regular {
 font-family: 'Roboto', Arial, sans-serif;
 font-weight: 400;
 font-style: normal;
}

@mixin roboto-medium {
 font-family: 'Roboto Medium', 'Roboto', Arial, sans-serif;
 font-weight: 500;
 font-style: normal;
}

@mixin roboto-bold {
 font-family: 'Roboto Bold', 'Roboto', Arial, sans-serif;
 font-weight: 700;
 font-style: normal;
}
