
$breakpoint-browser: 1094px;
$breakpoint-nav: 900px;
$breakpoint-mobile: 500px;
$min_width: 1094px;
$max_width: 1440px;
$min_font: 14px;
$max_font: 16px;
$stdLineHeight: 1.3;
$lrgLineHeight: 1.7;

:export {
  breakpointBrowser: $breakpoint-browser;
  breakpointMobile: $breakpoint-mobile;
}
