@use '@bt-branding/colors';

// ===Branding Repo color vars ===

// Brigttalk colour palette

/*
// Brigttalk colour palette
$bt-default:          #FFFFFF !default; //default white colour
$bt-primary:          #003CB2 !default; //ultramarie
$bt-secondary:        #003CB2 !default; //ultramarie
$bt-tertiary:         #003CB2 !default; //ultramarie
$bt-orange:           #F47D20 !default; //orange
$bt-red:              #EB0037 !default; //red  - fire red
$bt-dark-blue:        #122B4E !default; //dark blue Zodiac
$bt-med-blue:         #0073D1 !default; //med  blue Yale
$bt-light-blue:       #003CB2 !default; //light blue Sky
$bt-glacier-blue:     #81A8C4 !default; //blue Glacier
$bt-light-green:      #003CB2 !default; //light green
$bt-lighter-green:    #E3F4FF !default; //lighter green
$bt-london-grey:      #EDF0F2 !default; //london background grey
$bt-black:            #212121 !default; //dark black - text?
$bt-med-black:        #333333 !default; //med black - Mine Shaft
$bt-dark-grey:        #333333 !default; //retained historic var name re-asigned to 33333
$bt-light-grey:       #5C5C5C !default; //med grey - Chicago
$bt-silver-grey:      #F2F4F6 !default; //light grey - silver
$bt-bright-grey:      #CCD3DA !default; //light grey - Bright
$bt-lightest-grey:    #F2F4F6 !default; //light background grey - white smoke
$bt-lightest-grey2:   #F9F9FA !default; //lightest background grey -  Preserved as historical
$bt-purple:           #9423FC !default; // purple

// In case we ever change the color white to ? off white
$bt-white: $bt-default;

//Background Colours
$background:            $bt-default; //default white colour
$background-dark-grey:  $bt-light-grey; // darkish med grey
$background-grey:       $bt-lightest-grey;
$background-light-grey: $bt-lightest-grey2; // default grey background
$background-alt:        $bt-london-grey; //lightest grey
$background-footer:     $bt-light-grey; // light grey

// --- Text colors ---
$primary-text:   $bt-black; // black
$secondary-text: $bt-light-grey; // med grey - added for 0.1.0
$white-text:     $bt-white;

// Preserved as var name may be in use
$light-text:     $bt-light-grey; //light grey

// --- Links ---
$link-primary:   $bt-primary; //green
$link-secondary: $bt-secondary; //dark grey

// --- borders & shadow---

// page section divider - load more divider
$border-divider-color: $bt-bright-grey;

$box-shadow:           #00000029 !default;

// --- Icons ---
$icon-grey:  $bt-light-grey;
$icon-blue:  $bt-med-blue;
$icon-green: $bt-light-green;
$icon-red:   $bt-red;

// --- Alert colors ---
$bt-danger:                 $bt-red; //red
$alert-box-success:         #d4edda !default; //pastel green
$alert-box-success-border:  #c3e6cb !default; //darker-pastel green
$alert-box-heads-up:        #d1ecf1 !default; //pastel blue
$alert-box-heads-up-border: #bee5eb !default; //darker-pastel blue
$alert-box-warning:         #fff3cd !default; //pastel orange
$alert-box-warning-border:  #ffeeba !default; //darker-pastel orange
$alert-box-error:           #f5cccc !default; //pastel red
$alert-box-error-border:    #f1b8b8 !default; //darker-pastel red
*/
$box-shadow:   rgba(0,0,0,.161) !default;
$bt-white:     colors.$bt-white;
$bt-dark-blue: colors.$bt-dark-blue;
$bt-light-grey: colors.$bt-light-grey;

// Backgrounds
$background-default:     colors.$bt-white;
$background-dark-grey:   colors.$background-dark-grey;
$background-grey:        colors.$background-grey;
$background-light-grey:  colors.$background-light-grey;
$background-bright-grey: colors.$bt-bright-grey;
$background-alt:         colors.$background-alt;
$background-footer:      colors.$background-footer;
$really-light-grey:      colors.$bt-lightest-grey2 !default;
$background-disabled-inputs: colors.$background-light-grey;

// Text
$primary-text:   colors.$primary-text;
$secondary-text: colors.$secondary-text;
$white-text:     colors.$white-text;
$neutral-text: colors.$bt-black;

// Links
$link-primary:   colors.$link-primary;
$link-secondary: colors.$link-secondary;

// Borders / shadow
$box-border-color-light:   colors.$bt-bright-grey;
$buttons-bright-border:    colors.$bt-bright-grey;
$border-color-light-hover: colors.$bt-silver-grey;
$border-color-bright:      colors.$bt-bright-grey;
$border-color-focus: #E5E9EC;

$border-divider-color:        colors.$border-divider-color;
$border-divider-color-hover:  colors.$bt-silver-grey;
$form-border-highlight:       colors.$bt-silver-grey;
$form-border-highlight-green: colors.$bt-light-green;


$table-tr-divider:        colors.$bt-london-grey;
$box-shadow-color:        $box-shadow;

// Buttons

$button-primary-color:    colors.$bt-primary;
$button-secondary-color:  colors.$bt-primary;
$button-tertiary-color:   colors.$bt-primary;
$button-neutral-color:    colors.$bt-white;
$button-primary-disabled-color: #E9E9EC;


//Button psuedo states
// these hex colors should be in the branding repo!
$button-primary-focus-color:     #002244 !default;
$button-primary-hover-color:     colors.$bt-med-blue !default;
$button-primary-active-color:    colors.$bt-med-blue !default;

$button-secondary-focus-color:   #002244 !default;
$button-secondary-hover-color:   colors.$bt-lighter-green;
$button-secondary-active-color:  #002244 !default;

$button-tertiary-focus-color:    #002244 !default;
$button-tertiary-hover-color:    colors.$bt-lighter-green;
$button-tertiary-active-color:   #002244 !default;

$button-bright-focus-color:      colors.$background-alt;
$button-bright-hover-color:      colors.$bt-lightest-grey;
$button-bright-active-color:     colors.$bt-lightest-grey;
$button-disabled-background:     colors.$bt-silver-grey !default;

$button-neutral-focus-color:    colors.$bt-silver-grey;
$button-neutral-hover-color:    $really-light-grey;
$button-neutral-active-color:   colors.$bt-silver-grey;

// Icons
$icon-light-grey: colors.$bt-lightest-grey;
$icon-grey:  colors.$bt-med-blue;
$icon-blue:  colors.$icon-blue;
$icon-green: #33D7C8;
$icon-red:   colors.$icon-red;

// Message boxes
$update-success-background: #F5FFED;
$update-success-text: #0F5132;
$update-success-border: #D5E6C7;

$warning-background: #FFFBED;
$warning-text: #664D03;
$warning-border: #FAF0CE;

$update-error-background: #FFF8F7;
$update-error-text: #EB0037;
$update-error-border: #E7CECD;

$info-background: #F4FAFF;
$info-text: #003CB2;
$info-border: #C1DEF0;

// Labels

$background-live-now: colors.$bt-red;
